import React, { useState } from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ArrowDownIcon from "../../../assets/svg/new design/arrow-down-icon";
import LoadSvg from "../../../utils/LoadSvg";
import TrashIcon from "../../../assets/svg/new design/trash-icon";

interface SocialMediaLink {
  name: string;
  icon: string;
  url: string;
}

interface CommunitySocialProps {
  socialLink: SocialMediaLink;
  onUpdate: (updatedLink: SocialMediaLink) => void;
  onRemove: () => void;
  isLast: boolean;
  totalLinks: number;
  availableSocials: string[];
}

export default function CommunitySocial({
  socialLink,
  onUpdate,
  onRemove,
  isLast,
  totalLinks,
  availableSocials,
}: CommunitySocialProps) {
  const [urlError, setUrlError] = useState<string | null>(null);

  const socialOptions = [
    { name: "Thread", icon: "Thread" },
    { name: "Instagram", icon: "Instagram" },
    { name: "X", icon: "X" },
    { name: "Telegram", icon: "Telegram" },
    { name: "Youtube", icon: "Youtube" },
    { name: "Website", icon: "Website" },
    { name: "Facebook", icon: "Facebook" },
    { name: "TikTok", icon: "TikTok" },
    { name: "Linkedin", icon: "Linkedin" },
    { name: "Spotify", icon: "Spotify" },
    { name: "SoundCloud", icon: "SoundCloud" },
    { name: "SnapChat", icon: "SnapChat" },
    { name: "Wechat", icon: "Wechat" },
    { name: "Pinterest", icon: "Pinterest" },
    { name: "Reddit", icon: "Reddit" },
    { name: "Twitch", icon: "Twitch" },
  ].filter((option) => !availableSocials.includes(option.name));

  const isTrashDisabled = isLast && totalLinks === 1;

  const validateUrl = (url: string) => {
    if (url.trim() === "") {
      setUrlError("URL cannot be empty");
      return false;
    }

    try {
      new URL(url);
      setUrlError(null);
      return true;
    } catch (error) {
      setUrlError("Invalid URL format");
      return false;
    }
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let fullUrl = e.target.value.trim();
    if (fullUrl && !fullUrl.match(/^https?:\/\//)) {
      fullUrl = `https://${fullUrl}`;
    }
    onUpdate({ ...socialLink, url: fullUrl });
  };

  return (
    <div className="flex md:flex-row flex-col grow gap-4 mb-4">
      <div className="w-full md:w-[188px]">
        <FormControl variant="standard" fullWidth>
          <InputLabel
            id="social-name-label"
            className="flex-row"
            sx={{
              color: "#464646",
            }}
          >
            <span className=" flex flex-row items-center gap-1">
              {" "}
              Social Name{" "}
              <span className="flex justify-center bg-negative w-[8px] h-[8px] rounded-full items-center object-cover"></span>
            </span>
          </InputLabel>

          <Select
            labelId="social-name-label"
            value={socialLink.name || ""}
            onChange={(e) =>
              onUpdate({
                ...socialLink,
                name: e.target.value,
                icon: e.target.value,
              })
            }
            IconComponent={() => (
              <ArrowDownIcon className="w-4 h-4 text-[#464646] cursor-pointer" />
            )}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: "200px",
                  overflowY: "scroll",
                },
              },
            }}
            sx={{
              "&.MuiInputBase-root , &.Mui-focused .MuiSelect-select  , & .MuiSelect-select ,   &:hover .MuiSelect-select , &:hover .MuiSelect-select , &:focus .MuiSelect-select":
                {
                  backgroundColor: "white",
                },
              "&.MuiFormLabel-root-MuiInputLabel-root": {
                color: "#464646",
              },

              "& .MuiSelect-select::-moz-selection , & .MuiSelect-select::selection":
                {
                  backgroundColor: "transparent",
                },
            }}
          >
            {socialOptions.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                <div className="flex items-center gap-2">
                  <LoadSvg name={option.icon} size="20" color="#5C65FF" />
                  <span>{option.name}</span>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="w-full">
        <TextField
          value={socialLink.url.replace(/^https?:\/\//, "")}
          variant="standard"
          color="secondary"
          label={
            <span className="flex items-center gap-1">
              Social ID Link
              <span className="flex justify-center bg-negative w-[8px] h-[8px] rounded-full items-center object-cover"></span>
            </span>
          }
          onChange={handleUrlChange}
          onBlur={() => validateUrl(socialLink.url)}
          fullWidth
          error={!!urlError}
          placeholder="Insert Link"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div
                  onClick={!isTrashDisabled ? onRemove : undefined}
                  className={`${
                    isTrashDisabled ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                >
                  <TrashIcon
                    className={`w-4 h-8 ${
                      isTrashDisabled ? "text-[#4646461F]" : "text-[#464646]"
                    }`}
                  />
                </div>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-input, & .MuiInputLabel-root, & .MuiInput-underline:before":
              {
                color: "#464646",
              },
            "& .MuiInputLabel-root": {
              color: "#464646",
            },
            "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
              borderBottomColor: "#ECECEC",
            },
            "& .MuiInputBase-input::-moz-selection, & .MuiInputBase-input::selection":
              {
                backgroundColor: "transparent",
              },
          }}
        />
      </div>
    </div>
  );
}
