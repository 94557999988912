interface StyledProps {
  className?: string;
}
const TickIcon = (props: StyledProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.277 3.42a.5.5 0 0 1 .139.693l-6 9a.5.5 0 0 1-.77.076l-4-4a.5.5 0 1 1 .708-.707l3.568 3.569 5.662-8.493a.5.5 0 0 1 .693-.138"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default TickIcon;
