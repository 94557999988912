interface StyledProps {
  className?: string;
}
const PlusIcon = (props: StyledProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 2.836a.5.5 0 0 1 .5.5v4.5H13a.5.5 0 0 1 0 1H8.5v4.5a.5.5 0 0 1-1 0v-4.5H3a.5.5 0 1 1 0-1h4.5v-4.5a.5.5 0 0 1 .5-.5"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default PlusIcon;
