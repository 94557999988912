import {
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import React, { useState, Dispatch, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import httpClient from "../../../../API/RequestHandler";
import { auth, googleProvider } from "../../../../firebase";
import getAPIPath, { APIPaths } from "../../../../API/APIPaths";
import { Modal, TextField } from "@mui/material";
import { IDoerResponse } from "../../../../types/activity";
import { useSearchParams } from "react-router-dom";
import Spinner from "../../../common/Spinner/Spinner";

type AuthMode = "up" | "in";

const SignUp = ({
  setMode,
  setOpen,
  canBeclosed = true,
}: {
  setOpen: Dispatch<boolean>;
  setMode: Dispatch<AuthMode>;
  canBeclosed?: boolean;
}) => {
  const [fullname, setFullname] = useState<string>("");
  const [fulnameError, setFullnameError] = useState<boolean>(false);
  const [fullnameHelper, setFullnameHelper] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailHelper, setEmailHelper] = useState<string>("");

  const [verifyEmail, setVerifyEmail] = useState<string>("");
  const [verifyHelper, setVerifyHelper] = useState<string>("");
  const [verifyEmailError, setVerifyEmailError] = useState<boolean>(false);

  const [password, setPassword] = useState<string>("");
  const [passHelper, setPassHelper] = useState<string>("");
  const [passError, setPassError] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);

  const verify = () => {
    let verifyVal = true;
    setFullnameHelper("");
    setFullnameError(false);
    setEmailError(false);
    setEmailHelper("");
    setVerifyHelper("");
    setVerifyEmailError(false);
    setPassHelper("");
    setPassError(false);

    if (fullname === "") {
      setFullnameHelper("Please enter fullname.");
      setFullnameError(true);
      verifyVal = false;
    }

    if (email.trim() === "") {
      setEmailError(true);
      setEmailHelper("Please enter your email.");
      verifyVal = false;
    }

    if (email.trim() !== verifyEmail.trim() || email.trim() === "") {
      setVerifyHelper("Emails doesn't match");
      setVerifyEmailError(true);
      verifyVal = false;
    }

    if (password === "") {
      setPassHelper("Please enter your password.");
      setPassError(true);
      verifyVal = false;
    } else if (password.length < 6) {
      setPassHelper("Please enter your password at least 6 character.");
      setPassError(true);
      verifyVal = false;
    }
    return verifyVal;
  };

  const signup = () => {
    if (!verify()) return;
    setLoading(true);
    auth.signOut();
    localStorage.removeItem("token");
    httpClient
      .post(getAPIPath(APIPaths.SIGN_UP), {
        email: email,
        password: password,
        doerName: fullname,
        type: "email",
        fullName: fullname,
      })
      .then((res) => {
        signInWithEmailAndPassword(auth, email, password)
          .then((signInCredential) => {
            const user = signInCredential.user;

            sendEmailVerification(user)
              .then(() => {
                console.log("Verification email sent successfully.");
              })
              .catch((err) => {
                console.error("Failed to send verification email:", err);
              });

            updateProfile(user, { displayName: fullname });

            signInCredential.user.getIdToken().then((token) => {
              if (location.pathname === "/login") {
                let returnUrl = searchParams.get("returnUrl");
                window.location.href =
                  returnUrl === null || returnUrl === ""
                    ? "/create"
                    : returnUrl;
              } else {
                setOpen(false);
              }
              setLoading(false);
            });
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      })
      .catch((err) => {
        if (err?.response?.status === 500) {
          if (err?.response?.data?.title === "already-existst") {
            setEmailError(true);
            setEmailHelper("User already exists");
          } else if (err?.response?.data?.title === "invalid-argument") {
            setEmailError(true);
            setEmailHelper("Please enter correct email format.");
          }
        }
      });
  };

  const cancel = () => {
    if (!canBeclosed) {
      navigate(-1);
    } else {
      setOpen(false);
    }
  };

  const continueWithGoogle = () => {
    setLoading(true);
    signInWithPopup(auth, googleProvider)
      .then((res) => {
        res.user.getIdToken().then((token) => {
          localStorage.setItem("token", token);
          localStorage.setItem("uid", res.user.uid);
          httpClient
            .get<IDoerResponse>(getAPIPath(APIPaths.CHECK_DOER))
            .then((result) => {
              const id = result.data.result.id;
              if (id.length > 1) {
                if (location.pathname === "/login") {
                  let returnUrl = searchParams.get("returnUrl");
                  window.location.href =
                    returnUrl === null || returnUrl === ""
                      ? "/create"
                      : returnUrl;
                } else {
                  setOpen(false);
                }
              } else {
                httpClient
                  .post(getAPIPath(APIPaths.GOOGLE_CREATE_DOER), {
                    id: res.user.uid,
                    name: res.user.displayName,
                    email: res.user.email,
                  })
                  .then((res) => {
                    if (location.pathname === "/login") {
                      let returnUrl = searchParams.get("returnUrl");
                      window.location.href =
                        returnUrl === null || returnUrl === ""
                          ? "/create"
                          : returnUrl;
                    } else {
                      setOpen(false);
                    }
                    setLoading(false);
                  });
              }
            });
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <div className="h-full w-full flex justify-center items-center mt-32">
          <img
            alt=""
            src="/splash/boonoobationloading.gif"
            width={60}
            loading="lazy"
          />
        </div>
      )}
      <div className="flex z-50 lg:relative sm:absolute xs:absolute  top-0 bottom-0 right-0 left-0 items-center justify-center h-screen">
        <div
          className="bg-white py-3 lg:rounded-3xl sm:rounded-none xs:rounded-none overflow-auto xs:!w-full"
          style={{ width: "400px" }}
        >
          {canBeclosed && (
            <div className="mt-2 flex items-center gap-2 px-5">
              <button
                onClick={() => setOpen(false)}
                className="rounded-xl border-2 border-gray-200 py-2 px-3"
              >
                <img
                  alt=""
                  loading="lazy"
                  src="/event/back.png"
                  className="object-contain"
                  height="5px"
                  width="5px"
                />
              </button>
              <span className="text-gray-400 text-sm">Back</span>
            </div>
          )}
          <div className="px-5 pt-4 pb-2">
            <h1 className="font-interbold text-xl" style={{ color: "#2B2B2B" }}>
              Complete info
            </h1>
          </div>

          <div className="px-5 py-2">
            <TextField
              value={fullname}
              autoComplete="off"
              sx={{ my: "4px" }}
              variant="standard"
              error={fulnameError}
              helperText={fullnameHelper}
              fullWidth
              label={
                <span>
                  Full name<span className="text-red-600  ml-1">•</span>
                </span>
              }
              onChange={(e) => setFullname(e.currentTarget.value)}
            />

            <TextField
              type="email"
              value={email}
              autoComplete="off"
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
              sx={{ my: "4px" }}
              variant="standard"
              fullWidth
              error={emailError}
              helperText={emailHelper}
              label={
                <span>
                  Email<span className="text-red-600  ml-1">•</span>
                </span>
              }
              onChange={(e) => setEmail(e.currentTarget.value)}
            />

            <TextField
              type="email"
              value={verifyEmail}
              autoComplete="off"
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
              sx={{ my: "4px" }}
              helperText={verifyHelper}
              error={verifyEmailError}
              variant="standard"
              fullWidth
              label={
                <span>
                  Confirm Email<span className="text-red-600  ml-1">•</span>
                </span>
              }
              onChange={(e) => setVerifyEmail(e.currentTarget.value)}
            />

            <TextField
              value={password}
              autoComplete="off"
              error={passError}
              helperText={passHelper}
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
              sx={{ my: "4px" }}
              variant="standard"
              type="password"
              fullWidth
              label={
                <span>
                  Password<span className="text-red-600  ml-1">•</span>
                </span>
              }
              onChange={(e) => setPassword(e.currentTarget.value)}
            />

            <hr className="my-3" />
            <button
              onClick={() => continueWithGoogle()}
              className="flex my-5 p-2 w-full items-center border border-gray-200 rounded-2xl"
            >
              <img
                src="/event/google.png"
                width="35"
                loading="lazy"
                className="p-1 mr-2"
                alt=""
              />
              <div
                className="text-center whitespace-nowrap overflow-hidden text-ellipsis font-intersemibold border-l w-full border-gray-200 py-2 px-10"
                style={{ color: "#163A5F" }}
              >
                Continue with google
              </div>
            </button>
            <hr className="my-3" />

            <div className="text-sm font-interregular">
              <span style={{ color: "#1E1E1E" }}>Already have an account?</span>
              <button
                onClick={() => setMode("in")}
                className="text-primary font-intersemibold px-1"
              >
                Sign in
              </button>
            </div>
          </div>
          <div className="gap-2 bottom-0 right-0 left-0 py-2 px-5 h-32">
            <div className="mt-4 flex justify-around">
              <button
                onClick={cancel}
                className="font-intersemibold"
                style={{ color: "#AAABAB" }}
              >
                Cancel
              </button>

              <button
                onClick={signup}
                className="px-20 disabled:!bg-slate-400 font-intersemibold text-white py-3 rounded-2xl"
                style={{ backgroundColor: "#5C65FF" }}
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SignIn = ({
  setMode,
  setOpen,
  canBeclosed = true,
}: {
  setOpen: Dispatch<boolean>;
  setMode: Dispatch<AuthMode>;
  canBeclosed?: boolean;
}) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailHelper, setEmailHelper] = useState<string>("");
  const [invalidUser, setInvalidUser] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);

  const signIn = () => {
    if (!verify()) return false;
    setLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (!user.emailVerified) {
          sendEmailVerification(user).catch((err) => {
            console.log("Email verification error:", err);
          });
        }
        userCredential.user.getIdToken().then((token) => {
          localStorage.setItem("token", token);
          localStorage.setItem("uid", userCredential.user.uid);
          if (location.pathname === "/login") {
            let returnUrl = searchParams.get("returnUrl");
            window.location.href =
              returnUrl === null || returnUrl === "" ? "/create" : returnUrl;
          } else {
            setOpen(false);
          }
          setLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
        setInvalidUser("The user not found,invalid email or password!");
        setLoading(false);
      });
  };

  const verify = () => {
    let verifyVal = true;
    setEmailError(false);
    setEmailHelper("");
    setPasswordError("");

    if (email.trim() === "") {
      setEmailError(true);
      setEmailHelper("Please enter your email.");
      verifyVal = false;
    }

    if (password === "") {
      setPasswordError("Please enter your password.");
      verifyVal = false;
    }
    return verifyVal;
  };
  useEffect(() => {
    if (passwordError.trim() !== "") {
      setPasswordError("");
    }
    if (email.trim() !== "") {
      setEmailHelper("");
    }
    setInvalidUser("");
  }, [email, password]);

  const continueWithGoogle = () => {
    setLoading(true);
    signInWithPopup(auth, googleProvider)
      .then((res) => {
        res.user.getIdToken().then((token) => {
          localStorage.setItem("token", token);
          localStorage.setItem("uid", res.user.uid);
          httpClient
            .get<IDoerResponse>(getAPIPath(APIPaths.CHECK_DOER))
            .then((result) => {
              const id = result.data.result.id;
              if (id.length > 1) {
                if (location.pathname === "/login") {
                  let returnUrl = searchParams.get("returnUrl");
                  window.location.href =
                    returnUrl === null || returnUrl === ""
                      ? "/create"
                      : returnUrl;
                } else {
                  setOpen(false);
                }
              } else {
                httpClient
                  .post(getAPIPath(APIPaths.GOOGLE_CREATE_DOER), {
                    id: res.user.uid,
                    name: res.user.displayName,
                    email: res.user.email,
                  })
                  .then((res) => {
                    if (location.pathname === "/login") {
                      let returnUrl = searchParams.get("returnUrl");
                      window.location.href =
                        returnUrl === null || returnUrl === ""
                          ? "/create"
                          : returnUrl;
                    } else {
                      setOpen(false);
                    }
                    setLoading(false);
                  });
              }
            });
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const cancel = () => {
    if (!canBeclosed) {
      navigate(-1);
    } else {
      setOpen(false);
    }
  };

  const handleResetPasswordClick = () => {
    navigate("/reset-password", { state: { canGoBack: true } });
    setOpen(false);
  };

  return (
    <>
      {loading && (
        <div className="h-full w-full flex justify-center items-center mt-32">
          <img
            alt=""
            src="/splash/boonoobationloading.gif"
            width={60}
            loading="lazy"
          />
        </div>
      )}
      <div className="flex z-50 lg:relative sm:absolute xs:absolute lg:bg-inherit sm:bg-white xs:bg-white  top-0 bottom-0 right-0 left-0 items-center justify-center h-screen">
        <div
          className="bg-white py-3 lg:rounded-3xl sm:rounded-none xs:rounded-none overflow-auto xs:!w-full"
          style={{ width: "400px" }}
        >
          {canBeclosed && (
            <div className="mt-2 flex items-center gap-2 px-5">
              <button
                onClick={() => {
                  setOpen(false);
                }}
                className="rounded-xl border-2 border-gray-200 py-2 px-3"
              >
                <img
                  alt=""
                  src="/event/back.png"
                  className="object-contain"
                  height="5px"
                  loading="lazy"
                  width="5px"
                />
              </button>
              <span className="text-gray-400 text-sm">Back</span>
            </div>
          )}

          <div className="px-5 pt-4 pb-2">
            <h1 className="font-interbold text-xl" style={{ color: "#2B2B2B" }}>
              Complete info
            </h1>
          </div>

          <div className="px-5 py-2">
            <TextField
              value={email}
              type="email"
              autoComplete="off"
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
              className="py-6"
              sx={{ my: "4px" }}
              variant="standard"
              fullWidth
              error={emailError}
              helperText={emailHelper}
              label={
                <span>
                  Email<span className="text-red-600  ml-1">•</span>
                </span>
              }
              onChange={(e) => setEmail(e.currentTarget.value)}
            />

            <TextField
              value={password}
              autoComplete="off"
              error={passwordError !== ""}
              helperText={passwordError}
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
              className="py-6"
              sx={{ my: "4px" }}
              variant="standard"
              type="password"
              fullWidth
              label={
                <span>
                  Password<span className="text-red-600  ml-1">•</span>
                </span>
              }
              onChange={(e) => setPassword(e.currentTarget.value)}
            />

            <hr className="my-3" />

            {invalidUser && (
              <span className="text-negative text-xs">{invalidUser}</span>
            )}

            <button
              onClick={() => continueWithGoogle()}
              className="flex my-5 p-2 w-full items-center border border-gray-200 rounded-2xl"
            >
              <img
                src="/event/google.png"
                width="35"
                className="p-1 mr-2"
                alt=""
                loading="lazy"
              />
              <div
                className="text-center whitespace-nowrap overflow-hidden text-ellipsis font-intersemibold border-l w-full border-gray-200 py-2 px-10"
                style={{ color: "#163A5F" }}
              >
                Continue with google
              </div>
            </button>

            <hr className="my-3" />

            <div className="text-sm font-interregular">
              <span style={{ color: "#1E1E1E" }}>Don't have an account?</span>
              <button
                onClick={() => setMode("up")}
                className="text-primary font-intersemibold px-1"
              >
                Sign up
              </button>
            </div>
          </div>
          <div className="gap-2 bottom-0 right-0 left-0 py-2 px-5 h-32">
            <div className="mt-4 flex justify-around">
              <button
                onClick={cancel}
                className="font-intersemibold"
                style={{ color: "#AAABAB" }}
              >
                Cancel
              </button>

              <button
                onClick={signIn}
                className="px-20 disabled:!bg-slate-400 font-intersemibold text-white py-3 rounded-2xl"
                style={{ backgroundColor: "#5C65FF" }}
              >
                Sign In
              </button>
            </div>
            <div className="flex flex-row items-center mt-4 ">
              <span
                className=" text-sm font-interregular"
                style={{ color: "#1E1E1E" }}
              >
                Forgot Your Password?
              </span>
              <button
                className="text-primary font-intersemibold text-sm pl-2"
                onClick={handleResetPasswordClick}
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function AuthenticationPage(props: {
  open: boolean;
  setOpen: Dispatch<boolean>;
  canBeclosed?: boolean;
  authMode: AuthMode;
}) {
  const { open, setOpen, canBeclosed = true, authMode } = props;
  const [mode, setMode] = useState<AuthMode>(authMode);

  if (open === false) {
    return <></>;
  }
  switch (mode) {
    case "up":
      return (
        <>
          <div className="lg:block sm:hidden xs:hidden">
            <Modal
              open={open && window.innerWidth >= 1024}
              onClose={() => setOpen(false)}
            >
              <SignUp
                canBeclosed={canBeclosed}
                setOpen={setOpen}
                setMode={setMode}
              />
            </Modal>
          </div>
          <div className="lg:hidden sm:block xs:block fixed top-0 right-0 left-0 bottom-0 !z-50 min-h-screen sm:bg-transparent bg-white">
            <SignUp
              canBeclosed={canBeclosed}
              setOpen={setOpen}
              setMode={setMode}
            />
          </div>
        </>
      );
    case "in":
      return (
        <>
          <Modal open={open} onClose={() => setOpen(false)}>
            <SignIn
              canBeclosed={canBeclosed}
              setMode={setMode}
              setOpen={setOpen}
            />
          </Modal>
          <div className="lg:hidden sm:block xs:block fixed top-0 right-0 left-0 bottom-0 !z-50 min-h-screen sm:bg-transparent bg-white">
            <SignIn
              canBeclosed={canBeclosed}
              setMode={setMode}
              setOpen={setOpen}
            />
          </div>
        </>
      );
    default:
      return <div></div>;
  }
}
