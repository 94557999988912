interface StyledProps {
  className?: string;
}
const ArrowDownIcon = (props: StyledProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 13"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.265 8.476a.375.375 0 0 1-.53 0l-3.75-3.75a.375.375 0 0 1 .53-.53L6 7.68l3.485-3.484a.375.375 0 0 1 .53.53z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default ArrowDownIcon;
