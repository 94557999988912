import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import AuthenticationPage from "../CreateActivity/MainPageV2/AuthenticationPage";
import { auth } from "../../firebase";
import { useCreate } from "./hooks/useCreate";
import BreadCrumbs from "./BreadCrumbs/BreadCrumbs";
import CommunitySocials from "./CommunitySocials/CommunitySocials";
import TickIcon from "../../assets/svg/new design/tick-icon";
import ArrowLeftIcon from "../../assets/svg/new design/arrow-left-icon";
import { useAuth } from "../../context/authContent";

interface SocialMediaLink {
  name: string;
  icon: string;
  url: string;
}

export type ImagePageType = "Avatar" | "Cover" | "";

export default function CreateCommunitySocials() {
  const [loading, setLoading] = useState<boolean>(false);
  const [authenticationPageShow, setAuthenticationPageShow] = useState(false);
  const [socials, setSocials] = useState<SocialMediaLink[]>([]);
  const navigate = useNavigate();
  const { communityCover, communityAvatar, communityDetails, communityName } =
    useAuth();

  useEffect(() => {
    if (communityName === "") {
      navigate(-1);
    }
  }, [communityName, navigate]);

  const breadCrumbItems = [
    { label: "Discover Communities", href: "/community" },
    { label: "Create", href: "/community/create" },
  ];
  const handleLater = () => {
    if (auth.currentUser) {
      CreateCommunity();
    } else {
      setAuthenticationPageShow(true);
    }
  };
  const { CreateCommunity } = useCreate({
    coverUrl: communityCover,
    imageUrl: communityAvatar,
    description: communityDetails,
    title: communityName,
    setLoading,
    type: "create",
  });

  const handleCreateCommunity = () => {
    CreateCommunity(
      socials.reduce(
        (acc, social) => ({ ...acc, [social.name]: social.url }),
        {}
      )
    );
  };

  return (
    <div className="w-full m-auto ">
      <div className="flex items-center px-4 sm:px-0  py-6 sm:pt-12 sm:pb-6 gap-3 rounded-t-xl">
        <div className="flex flex-col gap-2">
          <h1 className="sm:text-[28px] text-[24px] sm:font-[700] font-[800] text-[#464646] ">
            Create Community
          </h1>
          <BreadCrumbs items={breadCrumbItems} />
        </div>
      </div>

      <div>
        <div className="flex flex-col  bg-white  p-6 gap-6 rounded-t-xl">
          <h1 className="sm:text-[28px] text-[24px] sm:font-[700] font-[800] text-[#464646] ">
            Add Social Links
          </h1>
          <CommunitySocials socials={socials} setSocials={setSocials} />
        </div>
      </div>
      <div>
        <div className="flex justify-between bg-white border-t-[1px] border-t-#ECECEC rounded-b-xl  p-6 ">
          <button
            onClick={() => navigate(-1)}
            className="flex px-4 py-2  h-[36px] rounded-[8px]  border text-darkGary  items-center  justify-center gap-2 "
          >
            <ArrowLeftIcon className="w-4 h-4 text-[#464646]" />
            <span className="text-[16px] font-[500]">Back</span>
          </button>

          <div className="flex flex-row justify-between gap-3">
            <button
              onClick={handleLater}
              className={`flex px-4 py-2 h-[36px] rounded-[8px] items-center justify-center bg-[#4646461F] text-[#464646] `}
            >
              <span className="text-[16px] font-[500] flex   items-center  whitespace-nowrap">
                <span className="md:flex hidden"> I'll Do It&nbsp; </span>
                Later
              </span>
            </button>

            <button
              onClick={handleCreateCommunity}
              className="flex px-4 py-2  h-[36px] rounded-[8px] bg-primary text-white items-center justify-center disabled:bg-white disabled:text-darkGary disabled:border"
              disabled={loading}
            >
              {loading ? (
                <img
                  alt=""
                  src="/splash/boonoobationloading.gif"
                  className="h-8 w-8 object-contain mx-auto block"
                  loading="lazy"
                />
              ) : (
                <span className="text-[16px] font-[500] flex flex-row gap-2 items-center">
                  <TickIcon className="w-4 h-4 text-white" />
                  Apply
                </span>
              )}
            </button>
          </div>
        </div>
      </div>

      {authenticationPageShow && (
        <AuthenticationPage
          open={authenticationPageShow}
          setOpen={setAuthenticationPageShow}
          authMode="in"
        />
      )}
    </div>
  );
}
