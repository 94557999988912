import { Dispatch } from "react";
import { auth, storage } from "../../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import getAPIPath, { APIPaths } from "../../../API/APIPaths";
import httpClient from "../../../API/RequestHandler";
import { v4 as uuidv4 } from "uuid";

export function useCreate({
  coverUrl,
  description,
  imageUrl,
  title,

  setLoading,
  type,
  communityId,
}: {
  coverUrl: string;
  description: string;
  imageUrl: string;
  title: string;

  setLoading: Dispatch<boolean>;
  type: "edit" | "create";
  communityId?: string;
}) {
  async function submitHandle(link: string) {
    if (link !== "") {
      if (link.includes("firebasestorage.googleapis.com")) {
        return link;
      }

      try {
        const response = await fetch(link, { mode: "cors" });
        const blob = await response.blob();
        return handleUpload(blob);
      } catch (error) {
        console.error("Error fetching image:", error);
        return link;
      }
    } else {
      return "";
    }
  }

  async function handleUpload(file: File | Blob): Promise<string> {
    if (!file) {
      throw new Error("No file provided.");
    }

    const name = auth?.currentUser?.uid + uuidv4();
    const storageRef = ref(storage, `/activity_image/${name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise<string>((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (err) => {
          console.error("Error during upload:", err);
          reject(err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              console.log("Download URL:", url);
              resolve(url);
            })
            .catch((error) => {
              console.error("Error getting download URL:", error);
              reject(error);
            });
        }
      );
    });
  }

  const CreateCommunity = async (socials?: { [key: string]: string }) => {
    setLoading(true);
    try {
      const url =
        type === "create"
          ? getAPIPath(APIPaths.CREATE_COMMUNITY)
          : getAPIPath(APIPaths.EDIT_COMMUNITY).replace(
              "{{id}}",
              communityId || ""
            );

      const coverPhotoUrl = await submitHandle(coverUrl);
      const avatarPhotoUrl = await submitHandle(imageUrl);

      const ownerId = auth.currentUser?.uid;
      if (!ownerId) return;
      const data = {
        title,
        description,
        coverUrl: coverPhotoUrl,
        imageUrl: avatarPhotoUrl,
        ownerId,
        socials,
      };
      const method = type === "create" ? "post" : "put";
      httpClient[method](url, data)
        .then((res) => {
          if (res.data.result?.id) {
            window.location.href = "/community/" + res.data.result.id;
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error during photo upload:", error);
    }
  };

  return {
    CreateCommunity,
  };
}
