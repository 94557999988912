interface StyledProps {
  className?: string;
}
const ArrowLeftIcon = (props: StyledProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.146 8.69a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708.707L6.207 8.336l4.647 4.646a.5.5 0 0 1-.708.707z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default ArrowLeftIcon;
