interface StyledProps {
  className?: string;
}
const TrashIcon = (props: StyledProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 2.985v.152a33 33 0 0 1 2.585.341.5.5 0 1 1-.17.986l-.14-.024-.67 8.713A2 2 0 0 1 10.611 15H5.389a2 2 0 0 1-1.994-1.847l-.67-8.713-.14.024a.5.5 0 1 1-.17-.986A32 32 0 0 1 5 3.137v-.152c0-1.043.808-1.933 1.877-1.967a35 35 0 0 1 2.246 0C10.192 1.052 11 1.942 11 2.985M6.91 2.017a34 34 0 0 1 2.182 0 .953.953 0 0 1 .91.968v.076a33 33 0 0 0-4 0v-.076c0-.529.405-.951.908-.968m-.237 3.964a.5.5 0 1 0-.999.038l.231 6a.5.5 0 1 0 1-.038zm3.654.038a.5.5 0 1 0-.999-.038l-.23 6a.5.5 0 1 0 .999.038z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default TrashIcon;
