import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigationIcon from "../../../assets/svg/new design/navigation-icon";

interface BreadCrumbItem {
  label: string;
  href?: string;
}
interface BreadCrumbsProps {
  items: BreadCrumbItem[];
}

function handleClick(href: string) {
  window.location.href = href;
}

export default function BreadCrumbs({ items }: BreadCrumbsProps) {
  const breadcrumbs = items.map((item, index) =>
    item.href ? (
      <Link
        underline="none"
        key={index}
        color={index === items.length - 2 ? "#5C65FF" : "#464646"}
        href={item.href}
        onClick={(event) => {
          event.preventDefault();
          handleClick(item.href!);
        }}
      >
        {item.label}
      </Link>
    ) : (
      <Typography
        key={index}
        sx={{ color: "text.primary", fontSize: "12px", fontWeight: "400" }}
      >
        {item.label}
      </Typography>
    )
  );
  return (
    <Stack spacing={2} className="h-full justify-center">
      <Breadcrumbs
        separator={
          <NavigationIcon className="w-3 h-3 text-[#5C65FF] flex items-center justify-center" />
        }
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}
