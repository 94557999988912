import React, { Dispatch, useState, useEffect } from "react";
import CommunitySocial from "./CommunitySocial";
import PlusIcon from "../../../assets/svg/new design/create-community-plus-icon";

interface SocialMediaLink {
  name: string;
  icon: string;
  url: string;
}

interface CommunitySocialsProps {
  socials: SocialMediaLink[];
  setSocials: Dispatch<React.SetStateAction<SocialMediaLink[]>>;
}

export default function CommunitySocials({
  socials,
  setSocials,
}: CommunitySocialsProps) {
  const [socialLinks, setSocialLinks] = useState<SocialMediaLink[]>(
    socials.length > 0 ? socials : [{ name: "X", icon: "X", url: "" }]
  );

  useEffect(() => {
    setSocials(socialLinks.filter((link) => link.name && link.url));
  }, [socialLinks, setSocials]);

  const addSocialLink = () => {
    if (socialLinks.length >= 5) {
      return;
    }
    setSocialLinks([...socialLinks, { name: "", icon: "", url: "" }]);
  };

  const updateSocialLink = (index: number, updatedLink: SocialMediaLink) => {
    const newLinks = [...socialLinks];
    newLinks[index] = updatedLink;
    setSocialLinks(newLinks);
  };

  const removeSocialLink = (index: number) => {
    if (socialLinks.length === 1) return;
    const newLinks = socialLinks.filter((_, i) => i !== index);
    setSocialLinks(newLinks);
  };

  const isLastSocialFilled = () => {
    const lastSocial = socialLinks[socialLinks.length - 1];
    return lastSocial.name !== "" && lastSocial.url !== "";
  };

  const getAvailableSocials = (index: number) => {
    const selectedSocials = socialLinks.map((link) => link.name);
    return selectedSocials.filter((_, i) => i !== index);
  };

  return (
    <>
      <div className="flex flex-col grow">
        {socialLinks.map((social, index) => (
          <CommunitySocial
            key={index}
            socialLink={social}
            onUpdate={(updatedLink) => updateSocialLink(index, updatedLink)}
            onRemove={() => removeSocialLink(index)}
            isLast={index === socialLinks.length - 1}
            totalLinks={socialLinks.length}
            availableSocials={getAvailableSocials(index)}
          />
        ))}
        <div className="mt-4">
          <button
            onClick={addSocialLink}
            className="flex items-center gap-2 text-[#5C65FF] font-semibold disabled:text-[#4646463D] disabled:cursor-not-allowed"
            disabled={socialLinks.length >= 5 || !isLastSocialFilled()}
          >
            <PlusIcon className="w-4 h-4" />
            Add Another Social Link ({5 - socialLinks.length} Left)
          </button>
        </div>
      </div>
    </>
  );
}
