export function SharePostIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 8.5C16.0523 8.5 16.5 8.05228 16.5 7.5C16.5 6.94772 16.0523 6.5 15.5 6.5C14.9477 6.5 14.5 6.94772 14.5 7.5C14.5 8.05228 14.9477 8.5 15.5 8.5ZM15.5 10C16.8807 10 18 8.88071 18 7.5C18 6.11929 16.8807 5 15.5 5C14.1193 5 13 6.11929 13 7.5C13 8.88071 14.1193 10 15.5 10Z"
        fill="#464646"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 13C9.05228 13 9.5 12.5523 9.5 12C9.5 11.4477 9.05228 11 8.5 11C7.94772 11 7.5 11.4477 7.5 12C7.5 12.5523 7.94772 13 8.5 13ZM8.5 14.5C9.88071 14.5 11 13.3807 11 12C11 10.6193 9.88071 9.5 8.5 9.5C7.11929 9.5 6 10.6193 6 12C6 13.3807 7.11929 14.5 8.5 14.5Z"
        fill="#464646"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 17.5C16.0523 17.5 16.5 17.0523 16.5 16.5C16.5 15.9477 16.0523 15.5 15.5 15.5C14.9477 15.5 14.5 15.9477 14.5 16.5C14.5 17.0523 14.9477 17.5 15.5 17.5ZM15.5 19C16.8807 19 18 17.8807 18 16.5C18 15.1193 16.8807 14 15.5 14C14.1193 14 13 15.1193 13 16.5C13 17.8807 14.1193 19 15.5 19Z"
        fill="#464646"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6361 8.1026C14.8556 8.45385 14.7489 8.91657 14.3976 9.1361L10.3976 11.6361C10.0463 11.8556 9.58363 11.7489 9.3641 11.3976C9.14457 11.0463 9.25135 10.5836 9.6026 10.3641L13.6026 7.8641C13.9539 7.64457 14.4166 7.75135 14.6361 8.1026Z"
        fill="#464646"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6361 15.8974C14.8556 15.5461 14.7489 15.0834 14.3976 14.8639L10.3976 12.3639C10.0463 12.1444 9.58363 12.2511 9.3641 12.6024C9.14457 12.9537 9.25135 13.4164 9.6026 13.6359L13.6026 16.1359C13.9539 16.3554 14.4166 16.2487 14.6361 15.8974Z"
        fill="#464646"
      />
    </svg>
  );
}
